import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useLayoutEffect } from "react";
import founderStyles from "./experts.styles";

const data = [
  {
    name: "Hutesh Kumar Gauttam",
    title: "Co-Founder",
    point1:
      "NIT Rourkela alumnus with 6 years of MNC experience in software engineering.",
    point2:
      "Patent holder with expertise in AI, computer vision, and leadership.",
    img: "./images/team_img_1_1.png",
  },
  {
    name: "Ajay Saini",
    title: "Co-Founder",
    point1: "NIT Rourkela alumnus with 6 years of IIT-JEE teaching experience.",
    point2:
      "Expert in operations, mentorship, career counseling, and entrepreneurship.",
    img: "./images/team_img_2_2.jpg",
  },
  {
    name: "Mukut Bihari Sharma",
    title: "Deputy Director",
    point1:
      "28+ years in academic building, experience as a District Resource Person at Azim Premji Foundation and facilitator for Government Teacher Training.",
    point2:
      "Expertise in course development, student mentorship, career counseling.",
    img: "./images/team_img_3.jpg",
  },
  {
    name: "Kapil Gupta",
    title: "Chief Academic Officer",
    point1:
      "Ex COO & HOD of Physics at Bansal Classes Kota, with over 20 years of experience in competitive exam training.",
    point2:
      "Focused on outsourcing the methodology to enhance access to quality education nationwide.",
    img: "./images/team_img_4.png",
  },
];

function ExpertsPage() {
  const classes = founderStyles;
  const [isMobile, setIsMobile] = useState(false);
  const [isTab, setIsTab] = useState(false);

  useLayoutEffect(() => {
    // Function to update screen width
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Change 768 to the desired breakpoint
      setIsTab(window.innerWidth < 1080);
    };

    // Initial check
    handleResize();

    // Listen for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Box>
      <Box style={{ position: "relative" }}>
        <img
          style={{ width: "100% ", height: 250, background: "#0F1B31" }}
          src="./images/bannerImg.png"
        />
        <Box
          style={{
            position: "absolute",
            textAlign: "center",
            width: "100%",
            height: "100%",
            top: "0px",
            left: "0px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: "700",
              fontSize: isTab ? "24px" : "60px",
              lineHeight: isTab ? "36px" : "62.6px",
              color: "white",
            }}
          >
            EXPERTS
          </Typography>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: "400",
              fontSize: isTab ? "20px" : "30px",
              lineHeight: "37.8px",
              color: "#fff",
              marginTop: "10px",
            }}
          >
            HOME{" "}
            <span
              style={{
                fontSize: "30px",
                padding: "0px 0px 6px 6px",
                fontWeight: "bolder",
              }}
            >
              →
            </span>{" "}
            EXPERTS
          </Typography>
          {/* <Typography style={{fontSize: '15px', color: '#fff'}}>Home→ MISSION & VISION</Typography> */}
        </Box>
      </Box>
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        spacing={2}
        px={isTab ? "20px" : "50px"}
        pr={isTab ? "20px" : "40px"}
        pl={isTab ? "32px" : "40px"}
        mt={isMobile ? 2 : 6}
        mb={isMobile ? 2 : 6}
      >
        {data.map((item, index) => (
          <Grid
            xs={12}
            sm={5.8}
            md={5.8}
            lg={5.8}
            flexWrap={isTab ? "wrap" : "nowrap"}
            mt={2}
          >
            <div
              style={{
                ...classes.borderBox,
                padding: isTab ? "10px" : "40px 40px 10px",
                flexWrap: isMobile ? "nowrap" : "nowrap",
                height: "100%",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <img
                  src={item.img}
                  style={{
                    width: isTab ? "140px" : "190px",
                    height: isTab ? "140px" : "190px",
                    minHeight: isTab ? "140px" : "190px",
                    objectFit: "cover",
                    margin: "0px",
                    borderRadius: "10px",
                    maxWidth: "190px",
                    border: "1px solid #1377C0",
                  }}
                />
                <div
                  style={{
                    bottom: "10px",
                    background: "#fff",
                    left: "15px",
                    borderRadius: "8px",
                    marginTop: isMobile ? "0px" : "10px",
                  }}
                >
                  <h2
                    style={{
                      fontSize: isTab ? "20px" : "28px",
                      fontFamily: "Outfit",
                      fontWeight: "700",
                      lineHeight: isTab ? "25.5px" : "31.5px",
                      marginTop: "10px",
                      textWrap: "wrap",
                      marginBottom: "0px",
                    }}
                  >
                    {item.name}
                  </h2>
                  <p
                    style={{
                      fontSize: isTab ? "16px" : "20px",
                      fontFamily: "Outfit",
                      fontWeight: "400",
                      lineHeight: isTab ? "18px" : "24px",
                      marginBottom: "10px",
                      color: "#1377C0",
                      marginTop: "6px",
                    }}
                  >
                    {item.title}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  marginLeft: isTab ? 0 : 4,
                  padding: 0,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    fontSize: "25px",
                    padding: isTab ? "10px" : "10px",
                    height: isMobile ? "auto" : "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <ul
                    style={{
                      fontSize: isTab ? "14px" : "20px",
                      fontFamily: "Outfit",
                      fontWeight: "300",
                      lineHeight: isTab ? "20px" : "28px",
                      textAlign: "justify",
                      color: "#000",
                      paddingLeft: isTab ? "18px" : "auto",
                    }}
                  >
                    <li>{item.point1}</li>
                    <li>{item.point2}</li>
                  </ul>
                </div>
                <a href="https://rzp.io/rzp/c3vbneNT">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      width: isTab ? "150px" : "285px",
                      height: isTab ? "40px" : "45px",
                      background: "#1377C0",
                      borderRadius: "8px",
                      color: "#fff",
                      fontWeight: "700",
                      marginTop: "auto",
                      fontSize: isTab ? "12px" : "20px",
                      display: "flex",
                      alignItems: "center",
                      fontFamily: "Outfit",
                      marginLeft: isTab ? "20px" : "10px",
                      minHeight: isTab ? "40px" : "45px",
                    }}
                  >
                    Book Now
                  </Button>
                </a>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default ExpertsPage;
