import { Padding } from "@mui/icons-material";

const styles ={
    topBox:{
        padding: '0px 40px',
        width: '100%',
  
       
       
        borderRadius: '10px',
   },
  input: {
    width: '100%',
    border:"none",
    fontfamily:"Outfit",
    padding:' 10px 10px',
    borderRadius:'6px',
    background:"#F3F7FB"
}

}
export default styles;