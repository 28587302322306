import { Padding } from "@mui/icons-material";

const aboutStyles ={
    box:{
        width: '80%',
        height: '600px',
        position: 'relative',
        
    },
  //   boxImg:{
  //     position: 'absolute',
      
  // },
    imagePlaceholder: {
        width: '80%',
        height: '100%',
       
      },
    text:{
        width: '100%',
     
        display: 'flex',
        height:'100%'
   
    },
    about:{
    
        color:'#1377C0',
      
        fontSize: '30px',
    },
    // aboutHeading:{
    //     fontWeight: '700',
    //     color:'#000',
    //     paddingBottom: '5px',
    //     fontSize: '30px',
     
    // },
    aboutSubText:{
        marginTop: '2px',
        color: '#000',
        fontSize: '15px',

        fontFamily: 'Outfit',
        fontWeight: '300',
       
        
    },
    button:{
        marginTop: '2px'
    },
  
      aboutImg:{
        maxHeight: '60px',
        maxWidth: '60px',
        padding: '10px 0px 0px 15px'
      },
      CardImg:{
        maxHeight: '50px',
        maxWidth: '50px',
        margin: '19px 0px 0px 30px',
        borderRadius: '50%',
        backgroundColor: '#E1F2FE',
      },
      aboutText:{
        fontWeight: '700',
      },
      number:{
        fontFamily: 'Outfit',
        fontWeight: '900',
        fontSize: '50px',
        color: '#000000',
      },
      TotalNumber:{
        fontFamily: 'Outfit',
        
        fontWeight: '400',
        fontSize: '25px',
        color: '#1377C0',
        padding:'10px 0px 0px'
        
      }

      

}
export default aboutStyles;