const founderStyles = {
box1:{
    display:'flex', flexDirection:'column',
    
},

media:{
    width: 251 , borderRadius:'10px',  margin:'15px'
},

media2:{
    width: 351 ,borderRadius:'10px', margin:'15px'
},

borderBox:{
    boxShadow:'0px 5px 30px 0px #4C58681A',display:'flex',
}
}
export default founderStyles;