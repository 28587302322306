import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useLayoutEffect } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import contactStyles from "./BecomePartner.styles";
import Swal from "sweetalert2";
function BecomePartner() {
  const classes = contactStyles;
  const [isMobile, setIsMobile] = useState(false);
  const [isTab, setIsTab] = useState(false);
  // Form fields
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [feeRange, setFeeRange] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useLayoutEffect(() => {
    // Function to update screen width
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Change 768 to the desired breakpoint
      setIsTab(window.innerWidth < 1080);
    };

    // Initial check
    handleResize();

    // Listen for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const validatePhoneNumber = (number: string) => {
    const phoneRegex = /^[0-9]{10,11}$/;
    return phoneRegex.test(number);
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const sendEnquiry = async () => {
    // Form validations
    if (!validatePhoneNumber(phone)) {
      Swal.fire({
        title: "Invalid Phone Number",
        text: "Please enter a valid 10 or 11-digit phone number.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    if (!validateEmail(email)) {
      Swal.fire({
        title: "Invalid Email",
        text: "Please enter a valid email address.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    try {
      setIsLoading(true)
      const response = await fetch(
        "https://backendapi.axisinfoline.com/enquiry/message/gaanvwala/send",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fistName: firstName,
            lastName: lastName,
            phone: phone,
            email: email,
            city: city,
            district: district,
            state: state,
            zipCode: zipCode,
            feeRange: feeRange,
            message: message,
          }),
        }
      );

      if (response.ok) {
        Swal.fire({
          title: "Success!",
          text: "Your enquiry has been sent successfully.",
          icon: "success",
          confirmButtonText: "OK",
        });
        setFirstName("")
        setLastName("")
          setPhone("")
          setEmail("")
        setCity("")
          setDistrict("")
          setState("")
          setZipCode("")
          setFeeRange("")
          setMessage("")
        
      } else {
        Swal.fire({
          title: "Error",
          text: "Something went wrong. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "An error occurred. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
      console.error("Error:", error);
    }
    setIsLoading(false)
  };
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Box style={{ position: "relative" }}>
            <img
              style={{ width: "100% ", height: 250, background: "#0F1B31" }}
              src="./images/bannerImg.png"
            />
            <Box
              style={{
                position: "absolute",
                textAlign: "center",
                width: "100%",
                height: "100%",
                top: "0px",
                left: "0px",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontWeight: "700",
                  fontSize: isTab ? "24px" : "60px",
                  lineHeight: isTab ? "36px" : "62.6px",
                  color: "white",
                }}
              >
                BECOME A PARTNER
              </Typography>
              <Typography
                style={{
                  fontFamily: "Outfit",
                  fontWeight: "400",
                  fontSize: isTab ? "20px" : "30px",
                  lineHeight: "37.8px",
                  color: "#fff",
                  marginTop: "10px",
                }}
              >
                HOME{" "}
                <span
                  style={{
                    fontSize: "30px",
                    padding: "0px 0px 6px 6px",
                    fontWeight: "bolder",
                  }}
                >
                  →
                </span>{" "}
                BECOME A PARTNER
              </Typography>
              {/* <Typography style={{fontSize: '15px', color: '#fff'}}>Home→ MISSION & VISION</Typography> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        px={isTab ? "20px" : "50px"}
        py={isTab ? "20px" : "40px"}
        mt={6}
        justifyContent="space-between"
      >
        <Grid item xs={12} sm={isMobile ? 12 : 6}>
          <Box>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: "400",
                fontSize: isTab ? "20px" : "30px",
                lineHeight: "37.8px",
                color: "#1377C0",
              }}
            >
              Join the
            </Typography>
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: "700",
                fontSize: isTab ? "24px" : "50px",
                lineHeight: isTab ? "36px" : "62.6px",
              }}
            >
              Gaanv Wala Partner Program!
            </Typography>
          </Box>
          <div style={{ marginTop: "10px" }}>
            <Typography
              variant="body1"
              style={{
                marginTop: "2px",
                color: "#000",
                fontSize: "15px",

                fontFamily: "Outfit",
                fontWeight: "300",
                margin: isTab ? "2px 0px" : "4% 0px",
              }}
              align="justify"
            >
              Empower rural communities by associating your school or
              educational institute with GAANV Wala. Join us as a Gaanv Wala
              Partner to make quality education accessible and affordable.
              Here's why it's the right choice for you:
            </Typography>
            <ul
              style={{
                marginTop: "2px",
                color: "#000",
                fontSize: "15px",

                fontFamily: "Outfit",
                fontWeight: "300",
                paddingRight: "25px",
                paddingLeft: "20px",
                margin: "30px 0px",
              }}
            >
              <li>
                <b style={{ fontWeight: "700" }}>Support Local Growth:</b> Bring
                world-class educational tools to your community.
              </li>
              <li style={{ marginTop: "10px" }}>
                <b style={{ fontWeight: "700" }}>Exclusive Benefits:</b>Access
                unique resources, training, and financial perks.
              </li>
              <li style={{ marginTop: "10px" }}>
                <b style={{ fontWeight: "700" }}>Network Expansion:</b> Connect
                with educators and administrators nationwide.
              </li>
              <li style={{ marginTop: "10px" }}>
                <b style={{ fontWeight: "700" }}>Flexible Collaboration:</b>{" "}
                Customize the partnership to fit your institution's needs.
              </li>
              <li style={{ marginTop: "10px" }}>
                <b style={{ fontWeight: "700" }}>Ongoing Support:</b> Receive
                dedicated guidance for your school's growth and success.
              </li>
            </ul>
            <p
              style={{
                margin: "1px 0px",
                color: "#000",
                fontSize: "15px",

                fontFamily: "Outfit",
                fontWeight: "300",
              }}
            >
              Become a part of this exciting initiative to uplift education
              standards in your region!
            </p>
            <p
              style={{
                margin: "2px 0px",
                color: "#000",
                fontSize: "15px",

                fontFamily: "Outfit",
                fontWeight: "700",
              }}
            >
              Partner With Us Today!
            </p>
          </div>
        </Grid>
        <Grid
          container
          xs={12}
          sm={isMobile ? 12 : 5}
          display="flex"
          borderRadius="8px"
          padding={isTab?"20px":"30px 30px"}
          ml={isTab ? "12px" : "0px"}
          width={isMobile ? "100%" : "auto"}
          bgcolor="#EFF8FF"
          mt={isMobile ? 6 : 0}
        >
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: "700",
              fontSize: isTab ? "24px" : "48px",
              lineHeight: isTab ? "36px" : "62.6px",
              marginBottom: "20px",
            }}
          >
            Send us Message
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                padding: "5px 20px 5px 10px",
                marginBottom: "15px",
                background: "#fff",
              }}
            >
              <input
              value={firstName}
                placeholder="First Name*"
                style={{
                  ...classes.input,
                  fontFamily: "Outfit",
                  width: "100%",
                  fontSize: isTab?"14px":"20px",
                  padding:isMobile?'6px':'10px 10px',
                }}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                padding: "5px 20px 5px 10px",
                marginBottom: "15px",
                background: "#fff",
              }}
            >
              <input
               value={lastName}
                placeholder="Last Name*"
                style={{
                  ...classes.input,
                  fontFamily: "Outfit",
                  width: "100%",
                  fontSize: isTab?"14px":"20px",
                  padding:isMobile?'6px':'10px 10px',
                }}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                padding: "5px 20px 5px 10px",
                marginBottom: "15px",
                background: "#fff",
              }}
            >
              <input
               value={phone}
                placeholder="Phone Number*"
                style={{
                  ...classes.input,
                  fontFamily: "Outfit",
                  width: "100%",
                  fontSize: isTab?"14px":"20px",
                  padding:isMobile?'6px':'10px 10px',
                }}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                padding: "5px 20px 5px 10px",
                marginBottom: "15px",
                background: "#fff",
              }}
            >
              <input
               value={email}
                placeholder="Email Address*"
                style={{
                  ...classes.input,
                  fontFamily: "Outfit",
                  width: "100%",
                  fontSize: isTab?"14px":"20px",
                  padding:isMobile?'6px':'10px 10px',
                }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                padding: "5px 20px 5px 10px",
                marginBottom: "15px",
                background: "#fff",
              }}
            >
              <input
               value={city}
                placeholder="City/Town*"
                style={{
                  ...classes.input,
                  fontFamily: "Outfit",
                  width: "100%",
                  fontSize: isTab?"14px":"20px",
                  padding:isMobile?'6px':'10px 10px',
                }}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                padding: "5px 20px 5px 10px",
                marginBottom: "15px",
                background: "#fff",
              }}
            >
              <input
               value={district}
                placeholder="District*"
                style={{
                  ...classes.input,
                  fontFamily: "Outfit",
                  width: "100%",
                  fontSize: isTab?"14px":"20px",
                  padding:isMobile?'6px':'10px 10px',
                }}
                onChange={(e) => setDistrict(e.target.value)}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                padding: "5px 20px 5px 10px",
                marginBottom: "15px",
                background: "#fff",
              }}
            >
              <input
               value={state}
                placeholder="State*"
                style={{
                  ...classes.input,
                  fontFamily: "Outfit",
                  width: "100%",
                  fontSize: isTab?"14px":"20px",
                  padding:isMobile?'6px':'10px 10px',
                }}
                onChange={(e) => setState(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                padding: "5px 20px 5px 10px",
                marginBottom: "15px",
                background: "#fff",
              }}
            >
              <input
               value={zipCode}
                placeholder="ZipCode"
                style={{
                  ...classes.input,
                  fontFamily: "Outfit",
                  width: "100%",
                  fontSize: isTab?"14px":"20px",
                  padding:isMobile?'6px':'10px 10px',
                }}
                onChange={(e) => setZipCode(e.target.value)}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                padding: "5px 20px 5px 10px",
                marginBottom: "15px",
                background: "#fff",
              }}
            >
              <input
               value={feeRange}
                placeholder="Select Fee Range"
                style={{
                  ...classes.input,
                  fontFamily: "Outfit",
                  width: "100%",
                  fontSize: isTab?"14px":"20px",
                  padding:isMobile?'6px':'10px 10px',
                }}
                onChange={(e) => setFeeRange(e.target.value)}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              padding: "5px 20px 5px 10px",
              marginBottom: "15px",
              background: "#fff",
              alignItems: "flex-start",
            }}
          >
            <textarea
             value={message}
              placeholder="Write Message"
              style={{
                ...classes.input,
                fontFamily: "Outfit",
                width: "100%",
                height: "130px",
                fontSize: isTab?"14px":"20px",
                padding:isMobile?'6px':'10px 10px',
              }}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            style={{
              width: isTab ? "auto" : "fit-content",
              height: isTab ? "40px" : "50px",
              background: "#1377C0",
              borderRadius: "8px",
              color: "#fff",
              fontWeight: "700",
              marginTop: "10px",
              fontSize: isTab ? "12px" : "14px",
              display: "flex",
              alignItems: "center",
              margin:isTab?"auto":'0px'
            }}
            disabled={isLoading}
            onClick={sendEnquiry}
          >
            SEND MESSAGE US
            {isLoading?<>
              <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" width="40px" height="40px"
  viewBox="0 0 100 100" enable-background="new 0 0 0 0">
    <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
      <animateTransform 
         attributeName="transform" 
         attributeType="XML" 
         type="rotate"
         dur="1s" 
         from="0 50 50"
         to="360 50 50" 
         repeatCount="indefinite" />
  </path>
</svg>
            </>:<>
            <span style={{ fontSize: "30px", padding: "0px 0px 6px 6px" }}>
              →
            </span>
            </>}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default BecomePartner;
