import React, { useState, useEffect ,useLayoutEffect} from "react";
import {
  Grid,
  Card,
  Box,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
interface ServiceCardProps {
  item: {
    heading?: string;
    title: string;
    subText: string;
    points: { point: string; text: string }[];
    bottomText?: string;
    bottomTextPoints?: { point: string; text: string }[];
  };
  index: number;

  img: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  item,
  index,

  img,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isTab, setIsTab] = useState(false);

  useLayoutEffect(() => {
    // Function to update screen width
    const handleResize = () => {
      setIsMobile((window.innerWidth -150) < 768); // Change 768 to the desired breakpoint
      setIsTab(window.innerWidth-150 < 1080);
     
      
    };

    // Initial check
    handleResize();

    // Listen for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      mx={isTab ? 0 : 0}
      my={isTab ? 2 : 5}
      flexWrap={isTab ? "wrap" : "nowrap"}
    >
      <Card
        sx={{
          boxShadow: "0px 5px 30px 0px #4C58681A",
          display: "flex",
          padding: isTab ? "16px" : "30px 30px",
          flexWrap: isMobile ? "wrap" : "nowrap",
          borderRadius: "10px",
          flexDirection: isMobile
            ? "column"
            : index % 2
            ? "row-reverse"
            : "row",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: isTab ? "100%" : "auto",
            position: "relative",
            maxWidth: isMobile ? "100%" : isTab ? "330px" : "400px",
          }}
        >
          <img
            src={`${img}${index + 1}.png`}
            alt={`Service ${index + 1}`}
            style={{
              width: isMobile ? "100%" : isTab ? "100%" : "400px",
              height: isMobile ? "190px" : "100%",
              minHeight: isMobile ? "190px" : isTab ? "330px" : "400px",
              maxHeight: isMobile ? "190px" : isTab ? "100%" : "400px",
              objectFit: "cover",
              margin: "auto",
              borderRadius: "10px",
              minWidth: isMobile ? "100%" : isTab ? "300px" : "400px",
              maxWidth: isMobile ? "100%" : isTab ? "330px" : "400px",
            }}
          />
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "column" }}
          ml={isTab ? 0 : index % 2 ? 0 : 4}
          mr={isTab ? 0 : index % 2 ? 4 : 0}
        >
          <CardContent
            sx={{
              fontSize: "25px",
              padding: isMobile
                ? "20px 10px 0px"
                : isTab
                ? "20px 30px"
                : "0px 10px",
            }}
          >
            <Typography
              style={{
                fontSize: isTab ? "18px" : "30px",
                color: "#1377C0",
                fontWeight: "400",
                lineHeight: isTab ? "24px" : "40.4px",
                fontFamily: "Outfit",
                textTransform: "uppercase",
              }}
            >
              {item.heading}
            </Typography>
            <Typography
              style={{
                fontSize: isTab ? "24px" : "40px",
                color: "#0F1B31",
                fontWeight: "700",
                lineHeight: isTab ? "30px" : "50.4px",
                fontFamily: "Outfit",
              }}
              mb={isTab ? 1 : 3}
            >
              {item.title}
            </Typography>

            <p
              style={{
                fontSize: isTab ? "14px" : "18px",
                fontFamily: "Outfit",
                fontWeight: "300",
                lineHeight: isTab ? "20px" : "25.2px",
                textAlign: "justify",
                color: "#000",
                marginBottom: isMobile ? "10px" : "20px",
              }}
            >
              {item.subText}
            </p>

            <ul
              style={{
                fontSize: isTab ? "14px" : "18px",
                fontFamily: "Outfit",
                fontWeight: "300",
                lineHeight: isTab ? "20px" : "25.2px",
                marginBottom: "0px",
                color: "#000",
                listStyleType: "none",
                paddingLeft: "0px",
              }}
            >
              {(expanded ? item.points : item.points.slice(0, 2)).map(
                (point, idx) => (
                  <li
                    key={idx}
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "6px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: isTab ? "16px" : "24px",
                        marginBottom: isTab ? "0.5rem" : "1rem",
                      }}
                    >
                      ●
                    </p>
                    <p style={{ marginBottom: isTab ? "0rem" : "1rem" }}>
                      <span
                        style={{
                          fontWeight: "600",
                          textWrap: "nowrap",
                          marginBottom: isTab ? "0rem" : "1rem",
                        }}
                      >
                        {point.point}
                      </span>
                      <span> {point.text}</span>
                    </p>
                  </li>
                )
              )}
            </ul>

            {expanded && (
              <>
                <p
                  style={{
                    fontSize: isTab ? "14px" : "18px",
                    fontFamily: "Outfit",
                    fontWeight: "300",
                    lineHeight: isTab ? "20px" : "25.2px",
                    textAlign: "justify",
                    color: "#000",
                    marginBottom: isMobile ? "10px" : "20px",
                  }}
                >
                  {item.bottomText}
                </p>
                <ul
                  style={{
                    fontSize: isTab ? "14px" : "18px",
                    fontFamily: "Outfit",
                    fontWeight: "300",
                    lineHeight: isTab ? "20px" : "25.2px",
                    textAlign: "justify",
                    color: "#000",
                    listStyleType: "none",
                    paddingLeft: "0px",
                    marginBottom: "0px",
                  }}
                >
                  {item.bottomTextPoints != undefined &&
                    item.bottomTextPoints.map((point) => (
                      <li
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          gap: "6px",
                        }}
                      >
                        <p style={{ fontSize: "24px" }}>●</p>
                        <p>
                          <span
                            style={{
                              fontWeight: "600",
                              textWrap: "nowrap",
                            }}
                          >
                            {point.point}
                          </span>
                          <span> {point.text}</span>
                        </p>
                      </li>
                    ))}
                </ul>
              </>
            )}

            {item.points.length > 2 ||
            item.bottomText != "" ||
            item.bottomTextPoints ? (
              <Button
                variant="contained"
                color="primary"
                onClick={toggleExpand}
                style={{
                  width: isTab ? "auto" : "fit-content",
                  maxHeight: "40px",
                  background: "#fff",
                  borderRadius: "8px",
                  color: "#1377C0",
                  fontWeight: "600",
                  fontSize: isTab ? "12px" : "18px",
                  display: "flex",
                  alignItems: "center",
                  boxShadow: "none",
                  padding: "0px",
                }}
              >
                {expanded ? "SHOW LESS" : "VIEW MORE"}
                <span
                  style={{
                    fontSize: isTab ? "30px" : "40px",
                    padding: "0px 0px 6px 6px",
                  }}
                >
                  →
                </span>
              </Button>
            ) : (
              <></>
            )}
          </CardContent>
        </Box>
      </Card>
    </Grid>
  );
};

export default ServiceCard;
