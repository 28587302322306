import { Box, Grid, Button, Typography } from "@mui/material";
import React, { useState, useEffect, useLayoutEffect } from "react";
import founderStyles from "./productsServices.style";
import LearningProgram from "../../components/header/learningProgram/LearningProgram";
import ServiceCard from "./ServiceCard";
const class9To10 = [
  {
    name: "NEET",
    bg: "#F1FAFF",
  },
  {
    name: "IIT JEE",
    bg: "#FFF9EE",
  },
  {
    name: "NDA",
    bg: "#E8FFF6",
  },
  {
    name: "CUET",
    bg: "#FFEEEE",
  },
];

const class11To12 = [
  {
    name: "NEET",
    bg: "#F8F8FF",
  },
  {
    name: "IIT JEE",
    bg: "#F4F4F4",
  },
  {
    name: "NDA",
    bg: "#F4FCFF",
  },
  {
    name: "CUET",
    bg: "#FFF9EE",
  },
];

const allClass = [
  {
    name: "School Preparation",
    bg: "#F8F8FF",
  },
];

const services = [
  {
    title: "Academic Planning and Monitoring",
    subText:
      "We take care of the complete academic structure and execution for your school, ensuring seamless coordination and monitoring throughout the academic year. Our services include:",
    points: [
      {
        point: "Teaching Schedule:",
        text: "A well-structured schedule to guide teaching throughout the year.",
      },
      {
        point: "Lecture Plans:",
        text: "Detailed plans for every lesson to ensure effective teaching strategies.",
      },
      {
        point: "Test Paper Schedule:",
        text: "A comprehensive testing plan to prepare students for competitive and board exams.",
      },
    ],
    bottomText:
      "Our academic experts handhold teachers, offering continuous support and strategies to help them prepare students for school-level competitive exams and board exams.",
  },
  {
    title: "Comprehensive Study Material",
    subText:
      "Our study materials are designed by India's top academic experts, focused on NCERT-based content with a competitive exam approach. We offer:",
    points: [
      {
        point: "",
        text: "Level-based learning customized to students' interests and potential.",
      },
      {
        point: "",
        text: "Study materials covering Physics, Chemistry, Maths, Biology, Social Studies, Logical Reasoning, and various language subjects.",
      },
      {
        point: "",
        text: "Resources to prepare students for school-level competitive exams like Olympiads, IIT-JEE, NEET, UPSC, CLAT, and more.",
      },
    ],
    bottomText: "",
  },
  {
    title: "Review Tests & Mock Test Series",
    subText:
      "Regular testing is essential to track student progress and fine-tune teaching strategies. Our offering includes:",
    points: [
      {
        point: "Review Tests:",
        text: "Periodic assessments designed to gauge students' understanding.",
      },
      {
        point: "Mock Test Series:",
        text: "Comprehensive test series that simulate real exam conditions, covering both competitive and subjective exams.",
      },
    ],
    bottomText: "",
  },
  {
    title: "Teacher Training & Empanelment",
    subText:
      "We provide comprehensive training for teachers, ensuring they are equipped with the latest instructional techniques and strategies. Our services include:",
    points: [
      {
        point: "Empanelment of Faculty:",
        text: "HR support for hiring qualified teachers as per the school’s requirements.",
      },
      {
        point: "Subject-Specific Training:",
        text: "We offer training in Physics, Chemistry, Mathematics, and Biology for school-appointed teachers.",
      },
    ],
    bottomText: "",
  },
  {
    title: "Technology Support",
    subText:
      "Our technology solutions are designed to streamline the academic and administrative processes of schools:",
    points: [
      {
        point: "Teachers App:",
        text: "A mobile app for teachers to manage classroom activities, lesson plans, and student performance.",
      },
      {
        point: "Parents App:",
        text: "A platform for parents to monitor their child's academic progress and communicate with teachers.",
      },
      {
        point: "Academic Monitoring ERP: ",
        text: "An advanced system for tracking and managing academic performance and schedules.",
      },
      {
        point: "School Management ERP:",
        text: "Comprehensive software to manage school operations efficiently.",
      },
    ],
    bottomText: "",
  },
  {
    title: "Marketing and Career Support",
    subText:
      "We offer marketing and career counseling services to help schools attract more students and guide them in their career paths:",
    points: [
      {
        point: "Marketing Support:",
        text: "Assistance in promoting academic programs and attracting student enrollments.",
      },
      {
        point: "Career Counseling & Assessment: ",
        text: "Expert career counseling sessions and assessments to help students discover their strengths and career opportunities.",
      },
    ],
    bottomText: "",
  },
];

const services2 = [
  {
    title: "Academic Planning and Monitoring",
    subText:
      "We take responsibility for the entire academic structure of your school. This includes:",
    points: [
      {
        point: "Teaching Schedule:",
        text: "Structured yearly plans for academic delivery.",
      },
      {
        point: "Lecture Plans:",
        text: "Customized teaching strategies for different subjects.",
      },
      {
        point: "Test Paper Schedule: ",
        text: "Scheduled tests and exams for continuous evaluation. Our experts also guide teachers in preparing students for competitive exams like NEET, JEE, Olympiads, and board exams.",
      },
    ],
    bottomText: "",
  },
  {
    title: "Comprehensive Study Material",
    subText:
      "We offer NCERT-based study material tailored to various competitive exams. Our study resources include:",
    points: [
      {
        point: "",
        text: "Subject-wise content for Physics, Chemistry, Maths, Biology, Social Studies, Logical Reasoning, and more.",
      },
      {
        point: "",
        text: "Resources designed to prepare students for UPSC, Olympiads, IIT-JEE, NEET, CA, CLAT, and other competitive exams. The material is crafted by top academic experts and aligned with school-level competitive exam standards.",
      },
      {
        point: "",
        text: "Resources to prepare students for school-level competitive exams like Olympiads, IIT-JEE, NEET, UPSC, CLAT, and more.",
      },
    ],
    bottomText: "",
  },
  {
    title: "Review Tests & Mock Test Series",
    subText:
      "Regular testing helps assess student progress and fine-tunes teaching strategies. Our offering includes:",
    points: [
      {
        point: "Review Tests:",
        text: "Scheduled throughout the year to measure student understanding.",
      },
      {
        point: "Mock Test Series:",
        text: "Designed to simulate real exam conditions for better exam preparedness. These tests are based on competitive exams like Olympiads, IIT-JEE, UPSC, NEET, and board exams.",
      },
    ],
    bottomText: "",
  },
  {
    title: "Teacher Training & Empanelment",
    subText:
      "We support schools in hiring and training teachers to ensure quality education:",
    points: [
      {
        point: "Teacher Empanelment: ",
        text: "We provide HR support to help schools find qualified teachers.",
      },
      {
        point: "Subject-Specific Training:",
        text: "We train teachers in Physics, Chemistry, Mathematics, and Biology to enhance their instructional skills.",
      },
      {
        point: "Online and Onsite Training",
        text: "Training is available in English and Hindi, depending on school needs.",
      },
    ],
    bottomText: "",
  },
  {
    title: "Technology Support",
    subText:
      "To streamline school operations, we offer a comprehensive suite of technology solutions:",
    points: [
      {
        point: "Teachers App",
        text: "For managing lesson plans, student performance, and classroom activities.",
      },
      {
        point: "Parents App:",
        text: "A platform for parents to monitor student progress.",
      },
      {
        point: "Academic Monitoring ERP: ",
        text: "Tools to track academic performance and manage schedules.",
      },
      {
        point: "School Management ERP",
        text: "Complete software solutions to manage all school operations.",
      },
    ],
    bottomText: "",
  },
  {
    title: "Brand Building & Marketing Support",
    subText:
      "We help schools build a strong brand presence in their community by providing:",
    points: [
      {
        point: "Marketing Collateral: ",
        text: "Support in designing hoardings, pamphlets, brochures, and more.",
      },
      {
        point: "Events",
        text: "We organize seminars, workshops, and scholarship tests to attract students and build the school’s reputation.",
      },
      {
        point: "Social Media",
        text: "Support in managing Instagram, Facebook, YouTube, and LinkedIn to expand the school’s digital presence.",
      },
    ],
    bottomText: "",
  },
  {
    title: "Career Counselling & Assessment",
    subText: "Our GW Career Incubation Lab provides:",
    points: [
      {
        point: "Psychometric and Career Assessments: ",
        text: "Helping students understand their strengths, interests, and potential career paths.",
      },
      {
        point: "",
        text: "Access to a Career Library of over 3000 career options.",
      },
      {
        point: "Career Counselling: ",
        text: "Expert guidance to help students perform a SWOT analysis and identify suitable career options.",
      },
    ],
    bottomText: "",
  },
  {
    title: "Personal Career Mentor Support",
    subText:
      "Students are matched with mentors from various fields to receive personalized guidance in their career journey. With a pool of career mentors across industries, students gain valuable insights and advice on navigating their future careers.",
    points: [],
    bottomText: "",
  },
  {
    title: "School Management",
    subText: "We provide comprehensive management services, taking care of:",
    points: [
      {
        point: "Daily Operations: ",
        text: "Ensuring smooth academic delivery, teacher coordination, and student satisfaction.",
      },
      {
        point: "Quality Assurance: ",
        text: "We maintain high standards in both academic and administrative functions, helping schools establish a strong presence as a GAANV WALA branded Excellence Center.",
      },
    ],
    bottomText: "",
  },
];

const products = [
  {
    heading: "Career Analysis & Report Generation",
    title: "Understand Your Path to Success",
    subText:
      "Our Career Analysis tool is an MCQ-based test that generates a detailed 45-page report, helping students to self-analyze and make informed career decisions. The report is based on a thorough analysis of a student’s:",
    points: [
      { point: "Psychology", text: "" },
      { point: "Academic Expectations", text: "" },
      { point: "Skills & Interests", text: "" },
    ],
    bottomText:
      "This personalised report guides students through the career selection process, ensuring they are matched with careers that align with their strengths and aspirations.",
  },
  {
    heading: "Objective Test Papers for Classes 6-12",
    title: "Practice at Your Fingertips",
    subText:
      "Our Test Generator is a software application that creates unlimited test papers and questions for classes 6-12 (EM – English Medium). It’s designed for schools and students to prepare effectively by generating practice exams on demand.",
    points: [],
    bottomText: "",
  },
  {
    heading: "Academic Planner Tool",
    title: "Efficiently Manage Classroom Activities",
    subText:
      "Our Academic Planner is a powerful software tool that helps school management streamline classroom operations. Key features include:",
    points: [
      {
        point: "Batch Scheduling:",
        text: "Plan and organize batches efficiently.",
      },
      {
        point: "Class Planning:",
        text: "Schedule the number of classes needed for each chapter.",
      },
      {
        point: "Test Scheduling:",
        text: "Arrange tests systematically throughout the academic year.",
      },
      {
        point: "Timetable Planning:",
        text: "Create customized timetables for optimal classroom management.",
      },
    ],
    bottomText:
      "This tool helps schools micromanage academic planning, ensuring a smooth and organized educational experience.",
  },
  {
    heading: "Study Material",
    title: "Comprehensive, Customized Study Resources",
    subText:
      "Our study material is designed to be easy to understand and practical, integrating experiments to enhance learning. Key features include:",
    points: [
      {
        point: "Customizable as per Your Branding:",
        text: "Tailored materials to fit your school’s identity and curriculum needs.",
      },
      {
        point: "Topic and Sub-topic Wise MCQs:",
        text: "Includes OMR sheets for systematic practice and evaluation.",
      },
    ],
    bottomText:
      "We offer tailored study materials for different academic levels:",
    bottomTextPoints: [
      {
        point: "For Classes 6-10 (EM):",
        text: "Comprehensive study material for school-level exams.",
      },
      {
        point: "For Classes 11-12 (HM & EM):",
        text: "Specialized material for JEE, NEET, and Board exams, including both Hindi  and English Medium options.",
      },
    ],
  },
  {
    heading: "Daily Practice Problems (DPPs) & Practice Question Banks",
    title: "Targeted Practice for Optimal Preparation",
    subText:
      "We provide Daily Practice Problems (DPPs) and Practice Question Banks for sustained learning and continuous assessment. These are available for:",
    points: [
      { point: "Classes 6-10 (EM):", text: "" },
      { point: "Classes 11-12 (HM & EM):", text: "" },
    ],
    bottomText:
      "Our resources are designed to reinforce learning through regular practice and cover a wide range of difficulty levels to challenge students and prepare them for their exams.",
  },
  {
    heading: "Test Papers",
    title: "Structured Testing for Exam Preparation",
    subText:
      "Our test papers are designed to assess students’ grasp of the material at different levels of their academic journey. We offer:",
    points: [
      {
        point: "Chapter-Wise Tests:",
        text: "Focused assessments to master each chapter.",
      },
      {
        point: "Unit Tests:",
        text: "Combined tests covering multiple chapters.",
      },
      {
        point: "Full-Syllabus Tests:",
        text: "Comprehensive tests that simulate final exam conditions.",
      },
    ],
    bottomText:
      "Available for Classes 6-10 (EM) and Classes 11-12 (HM & EM), these tests provide a structured path to mastering course material and preparing for school-level, JEE, and NEET exams.",
  },
];

const ProductServicesPage = () => {
  const classes = founderStyles;
  const [isMobile, setIsMobile] = useState(false);
  const [isTab, setIsTab] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  useLayoutEffect(() => {
    // Function to update screen width
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Change 768 to the desired breakpoint
      setIsTab(window.innerWidth < 1080);
    };

    // Initial check
    handleResize();

    // Listen for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box>
      <Box style={{ position: "relative" }}>
        <img
          style={{ width: "100% ", height: 250, background: "#0F1B31" }}
          src="./images/bannerImg.png"
        />
        <Box
          style={{
            position: "absolute",
            textAlign: "center",
            width: "100%",
            height: "100%",
            top: "0px",
            left: "0px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: "700",
              fontSize: isTab ? "24px" : "60px",
              lineHeight: isTab ? "36px" : "62.6px",
              color: "white",
            }}
          >
            PRODUCT & SERVICES
          </Typography>
          <Typography
            style={{
              fontFamily: "Outfit",
              fontWeight: "400",
              fontSize: isTab ? "20px" : "30px",
              lineHeight: "37.8px",
              color: "#fff",
              marginTop: "10px",
            }}
          >
            HOME{" "}
            <span
              style={{
                fontSize: "30px",
                padding: "0px 0px 6px 6px",
                fontWeight: "bolder",
              }}
            >
              →
            </span>{" "}
            PRODUCT & SERVICES
          </Typography>
          {/* <Typography style={{fontSize: '15px', color: '#fff'}}>Home→ MISSION & VISION</Typography> */}
        </Box>
      </Box>
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        pl={isTab ? "20px" : "70px"}
        pr={isTab ? "20px" : "70px"}
        py={isTab ? "20px" : "40px"}
        mt={isMobile ? 2 : 6}
      >
        <div
          style={{
            background: "#fff",
            border: "1px solid #1377C0",
            boxShadow: "0px 5px 30px 0px #4C58681A",
            width: "fit-content",
            borderRadius: "10px",
            display: "flex",
            margin: "auto",
          }}
        >
          <Typography
            style={{
              fontSize: isTab ? "14px" : "20px",
              background: currentTab == 0 ? "#1377C0" : "#fff",
              borderRadius: "10px",
              color: currentTab == 0 ? "#fff" : "#1377C0",
              fontWeight: "700",
              lineHeight: "50.4px",
              fontFamily: "Outfit",
              padding: isTab ? "0px 20px" : "0px 40px 0px 40px",
              cursor: "pointer",
            }}
            onClick={() => setCurrentTab(0)}
          >
            GW Academic Strengths
          </Typography>
          <Typography
            style={{
              fontSize: isTab ? "14px" : "20px",
              background: currentTab == 1 ? "#1377C0" : "#fff",
              borderRadius: "10px",
              color: currentTab == 1 ? "#fff" : "#1377C0",
              fontWeight: "700",
              lineHeight: "50.4px",
              fontFamily: "Outfit",
              padding: isTab ? "0px 20px" : "0px 40px 0px 40px",
              cursor: "pointer",
            }}
            onClick={() => setCurrentTab(1)}
          >
            Services
          </Typography>
        </div>
        {currentTab == 0 && (
          <>
            <Typography
              style={{
                fontSize: isTab ? "24px" : "40px",
                color: "#1377C0",
                fontWeight: "700",
                lineHeight: isTab ? "30px" : "50.4px",
                fontFamily: "Outfit",
              }}
              mt={4}
            >
              Empowering Students with Tailored Learning & Career Guidance
              Solutions
            </Typography>
            <Typography
              style={{
                fontSize: isTab ? "14px" : "20px",
                color: "#0F1B31",
                fontWeight: "300",
                lineHeight: isTab ? "20px" : "28px",
                fontFamily: "Outfit",
              }}
              mt={1}
            >
              At GW Tech, we provide innovative educational tools designed to
              meet the unique needs of students. Our products are tailored to
              empower students to make informed career decisions and achieve
              academic excellence. Below is a comprehensive overview of our key
              offerings:
            </Typography>
            <Grid mt={4}>
              {products.map((item, index) => (
                <ServiceCard
                  key={index}
                  item={item}
                  index={index}
                  img="./images/products_"
                />
              ))}
            </Grid>
            <LearningProgram showBtn={false} />
            <div id="programs">
              <Grid container spacing={2} p={isMobile ? 1 : 0}>
                {class9To10.map((item, index) => (
                  <Grid item md={3} sm={6} xs={12} key={index}>
                    <div
                      style={{
                        padding: "25px",
                        background: item.bg,
                        boxShadow: "0px 9px 15px 0px #0000000F",
                        position: "relative",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: isTab ? "20px" : "30px",
                          color: "#1377C0",
                          fontWeight: "500",
                          lineHeight: isTab ? "24px" : "26px",
                          fontFamily: "Outfit",
                        }}
                        mb={isTab ? 0.7 : 2}
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: isTab ? "15px" : "20px",
                          color: "#1E1E1E",
                          fontWeight: "400",
                          lineHeight: isTab ? "20px" : "25.2px",
                          fontFamily: "Outfit",
                        }}
                      >
                        Including Pre-Foundation
                      </Typography>
                      <Grid container spacing={1} width="80%" mt={1} mb={6}>
                        {item.name != "NDA" && item.name != "CUET" && (
                          <>
                            <Grid item xs={6}>
                              <div
                                style={{
                                  background: "#fff",
                                  border: "1px solid #1377C066",
                                  borderRadius: "30px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: "6px",
                                }}
                              >
                                <p
                                  style={{
                                    marginBottom: "0px",
                                    color: "#494949",
                                    fontFamily: "Outfit",
                                    fontSize: isTab ? "13px" : "16px",
                                  }}
                                >
                                  Class 9
                                </p>
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <div
                                style={{
                                  background: "#fff",
                                  border: "1px solid #1377C066",
                                  borderRadius: "30px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: "6px",
                                }}
                              >
                                <p
                                  style={{
                                    marginBottom: "0px",
                                    color: "#494949",
                                    fontFamily: "Outfit",
                                    fontSize: isTab ? "13px" : "16px",
                                  }}
                                >
                                  Class 10
                                </p>
                              </div>
                            </Grid>
                          </>
                        )}
                        <Grid item xs={6}>
                          <div
                            style={{
                              background: "#fff",
                              border: "1px solid #1377C066",
                              borderRadius: "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "6px",
                            }}
                          >
                            <p
                              style={{
                                marginBottom: "0px",
                                color: "#494949",
                                fontFamily: "Outfit",
                                fontSize: isTab ? "13px" : "16px",
                              }}
                            >
                              Class 11
                            </p>
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div
                            style={{
                              background: "#fff",
                              border: "1px solid #1377C066",
                              borderRadius: "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "6px",
                            }}
                          >
                            <p
                              style={{
                                marginBottom: "0px",
                                color: "#494949",
                                fontFamily: "Outfit",
                                fontSize: isTab ? "13px" : "16px",
                              }}
                            >
                              Class 12
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          width: isTab ? "auto" : "100%",
                          height: isTab ? "40px" : "50px",

                          borderRadius: "8px",
                          color: "#464646",
                          fontWeight: "300",
                          // marginTop: '20px',
                          fontSize: isTab ? "16px" : "20px",
                          display: isTab ? "none" : "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          margin: isTab ? "0px" : "0px",
                          fontFamily: "Outfit",
                          textWrap: "nowrap",
                          background: "transparent",
                          boxShadow: "none",
                          textTransform: "none",
                          paddingLeft: "0px",
                        }}
                      >
                        {/* Explore More
                      <span style={{ fontSize: "30px" }}>→</span> */}
                      </Button>

                      <img
                        style={{
                          width: isTab ? "70px" : "100px",
                          position: "absolute",
                          bottom: "20px",
                          right: "10px",
                        }}
                        src={`./images/courses_${index + 1}.png`}
                      />
                    </div>
                  </Grid>
                ))}
              </Grid>

              <Grid
                container
                spacing={2}
                p={isMobile ? 1 : 0}
                mt={isMobile ? 2 : 4}
              >
                {allClass.map((item, index) => (
                  <Grid item md={3} sm={6} xs={12} key={index}>
                    <div
                      style={{
                        padding: "25px",
                        background: item.bg,
                        boxShadow: "0px 9px 15px 0px #0000000F",
                        position: "relative",
                        minHeight: "315px",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: isTab ? "20px" : "30px",
                          color: "#1377C0",
                          fontWeight: "500",
                          lineHeight: isTab ? "24px" : "26px",
                          fontFamily: "Outfit",
                        }}
                        mb={2}
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: isTab ? "15px" : "20px",
                          color: "#1E1E1E",
                          fontWeight: "400",
                          lineHeight: isTab ? "20px" : "25.2px",
                          fontFamily: "Outfit",
                        }}
                      >
                        Foundation
                      </Typography>
                      <Grid container spacing={1} width="80%" mt={1} mb={2}>
                        <Grid item xs={6}>
                          <div
                            style={{
                              background: "#fff",
                              border: "1px solid #1377C066",
                              borderRadius: "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "6px",
                            }}
                          >
                            <p
                              style={{
                                marginBottom: "0px",
                                color: "#494949",
                                fontFamily: "Outfit",
                                fontSize: isTab ? "13px" : "16px",
                              }}
                            >
                              Class 6
                            </p>
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div
                            style={{
                              background: "#fff",
                              border: "1px solid #1377C066",
                              borderRadius: "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "6px",
                            }}
                          >
                            <p
                              style={{
                                marginBottom: "0px",
                                color: "#494949",
                                fontFamily: "Outfit",
                                fontSize: isTab ? "13px" : "16px",
                              }}
                            >
                              Class 7
                            </p>
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div
                            style={{
                              background: "#fff",
                              border: "1px solid #1377C066",
                              borderRadius: "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "6px",
                            }}
                          >
                            <p
                              style={{
                                marginBottom: "0px",
                                color: "#494949",
                                fontFamily: "Outfit",
                                fontSize: isTab ? "13px" : "16px",
                              }}
                            >
                              Class 8
                            </p>
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div
                            style={{
                              background: "#fff",
                              border: "1px solid #1377C066",
                              borderRadius: "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "6px",
                            }}
                          >
                            <p
                              style={{
                                marginBottom: "0px",
                                color: "#494949",
                                fontFamily: "Outfit",
                                fontSize: isTab ? "13px" : "16px",
                              }}
                            >
                              Class 9
                            </p>
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div
                            style={{
                              background: "#fff",
                              border: "1px solid #1377C066",
                              borderRadius: "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "6px",
                              fontSize: isTab ? "13px" : "16px",
                            }}
                          >
                            <p
                              style={{
                                marginBottom: "0px",
                                color: "#494949",
                                fontFamily: "Outfit",
                                fontSize: isTab ? "13px" : "16px",
                              }}
                            >
                              Class 10
                            </p>
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div
                            style={{
                              background: "#fff",
                              border: "1px solid #1377C066",
                              borderRadius: "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "6px",
                            }}
                          >
                            <p
                              style={{
                                marginBottom: "0px",
                                color: "#494949",
                                fontFamily: "Outfit",
                                fontSize: isTab ? "13px" : "16px",
                              }}
                            >
                              More..
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          width: isTab ? "auto" : "100%",
                          height: isTab ? "40px" : "50px",

                          borderRadius: "8px",
                          color: "#464646",
                          fontWeight: "300",
                          // marginTop: '20px',
                          fontSize: isTab ? "16px" : "20px",
                          display: isTab ? "none" : "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          margin: isTab ? "0px" : "0px",
                          fontFamily: "Outfit",
                          textWrap: "nowrap",
                          background: "transparent",
                          boxShadow: "none",
                          textTransform: "none",
                          paddingLeft: "0px",
                          marginTop: "auto",
                        }}
                      >
                        {/* Explore More
                      <span style={{ fontSize: "30px" }}>→</span> */}
                      </Button>

                      <img
                        style={{
                          width: isTab ? "70px" : "100px",
                          position: "absolute",
                          bottom: "20px",
                          right: "10px",
                        }}
                        src={`./images/courses_5.png`}
                      />
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          </>
        )}
        {currentTab == 1 && (
          <>
            <Typography
              style={{
                fontSize: isTab ? "24px" : "40px",
                color: "#1377C0",
                fontWeight: "700",
                lineHeight: isTab ? "30px" : "50.4px",
                fontFamily: "Outfit",
              }}
              mt={4}
            >
              GAANV WALA Academic Excellence Program (AEP)
            </Typography>
            <Typography
              style={{
                fontSize: isTab ? "14px" : "20px",
                color: "#0F1B31",
                fontWeight: "300",
                lineHeight: isTab ? "20px" : "28px",
                fontFamily: "Outfit",
              }}
              mt={1}
            >
              Our Academic Excellence Program (AEP) is designed to collaborate
              with schools to elevate the academic experience for students from
              Class 6 to 12. This School Integrated Program (SIP) takes full
              responsibility for academic planning and management, providing
              schools with relief from academic stress and offering students
              comprehensive preparation for competitive exams like NEET, JEE,
              NDA, CUET, CA, and more. Below are the key services we offer:
            </Typography>
            <Grid mt={4}>
              {services.map((item, index) => (
                <ServiceCard
                  key={index}
                  item={item}
                  index={index}
                  img="./images/services_"
                />
              ))}
            </Grid>

            <Typography
              style={{
                fontSize: isTab ? "24px" : "40px",
                color: "#1377C0",
                fontWeight: "700",
                lineHeight: isTab ? "30px" : "50.4px",
                fontFamily: "Outfit",
              }}
              mt={4}
            >
              GW Excellence Center – A Partnership Opportunity for Schools
            </Typography>
            <Typography
              style={{
                fontSize: isTab ? "14px" : "20px",
                color: "#0F1B31",
                fontWeight: "300",
                lineHeight: isTab ? "20px" : "28px",
                fontFamily: "Outfit",
              }}
              mt={1}
            >
              At GW Tech, we offer schools the opportunity to create a GW
              Excellence Center in collaboration with us. This program provides
              complete academic, management, and financial support for K-12
              schools, ensuring the smooth operation and success of the
              institution under the trusted GAANV WALA brand.
            </Typography>
            <Typography
              style={{
                fontSize: isTab ? "14px" : "20px",
                color: "#0F1B31",
                fontWeight: "300",
                lineHeight: isTab ? "20px" : "28px",
                fontFamily: "Outfit",
              }}
              mt={1}
            >
              We address common challenges faced by schools such as
              sustainability issues, brand management, and competition. Through
              expert guidance, we help schools build a successful academic
              program and foster a powerful brand identity. Here's how we
              achieve excellence:
            </Typography>
            <Grid mt={4}>
              {services2.map((item, index) => (
                <ServiceCard
                  key={index}
                  item={item}
                  index={index}
                  img="./images/services2_"
                />
              ))}
            </Grid>

            <Typography
              style={{
                fontSize: isTab ? "24px" : "40px",
                color: "#1377C0",
                fontWeight: "700",
                lineHeight: isTab ? "30px" : "50.4px",
                fontFamily: "Outfit",
              }}
              mt={4}
            >
              GW Tech Career Counselling Program
            </Typography>
            <Typography
              style={{
                fontSize: isTab ? "14px" : "20px",
                color: "#0F1B31",
                fontWeight: "300",
                lineHeight: isTab ? "20px" : "28px",
                fontFamily: "Outfit",
              }}
              mt={1}
            >
              Many schools and organizations seek to provide career counselling
              but lack access to industry-specific mentors and advisors. At GW
              Tech, we offer a tailored Career Counselling Program, where we
              bring expert mentors directly to your organization. We organize
              impactful seminars and 1-on-1 mentorship sessions with
              professionals from various fields, ensuring a personalized
              experience for students.
            </Typography>
            <Typography
              style={{
                fontSize: isTab ? "14px" : "20px",
                color: "#0F1B31",
                fontWeight: "300",
                lineHeight: isTab ? "20px" : "28px",
                fontFamily: "Outfit",
              }}
              mt={1}
            >
              This affordable, customized program helps schools and
              organizations create a meaningful and brand-building event that
              inspires and guides students toward their future careers.
            </Typography>

            <Typography
              style={{
                fontSize: isTab ? "24px" : "40px",
                color: "#1377C0",
                fontWeight: "700",
                lineHeight: isTab ? "30px" : "50.4px",
                fontFamily: "Outfit",
              }}
              mt={4}
            >
              Personalized Mentorship for Future Leaders
            </Typography>
            <Typography
              style={{
                fontSize: isTab ? "14px" : "20px",
                color: "#0F1B31",
                fontWeight: "300",
                lineHeight: isTab ? "20px" : "28px",
                fontFamily: "Outfit",
              }}
              mt={1}
            >
              At GW , we empower students from Class 9 to college through
              tailored 1:1 mentorship that helps them navigate career paths,
              professional growth, and academic challenges by focusing more on
              skills and interests. Founded by IITians and NITians, our expert
              mentors provide personalized guidance across various fields,
              including placements, interviews, government jobs,
              entrepreneurship, financial management, and MNC careers.
            </Typography>
            <Typography
              style={{
                fontSize: isTab ? "14px" : "20px",
                color: "#0F1B31",
                fontWeight: "300",
                lineHeight: isTab ? "20px" : "28px",
                fontFamily: "Outfit",
              }}
              mt={1}
            >
              Our goal is to foster long-term relationships, offering dedicated
              support at every step, from high school to the corporate world. We
              are committed to enriching students' educational experiences and
              preparing them for success in the global marketplace.
            </Typography>
            <Typography
              style={{
                fontSize: isTab ? "14px" : "20px",
                color: "#000",
                fontWeight: "600",
                lineHeight: isTab ? "20px" : "28px",
                fontFamily: "Outfit",
              }}
              mt={1}
            >
              Join us at GAANV WALA - Where Mentorship Meets Success.
            </Typography>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default ProductServicesPage;
